import {useMediaQuery} from "@uidotdev/usehooks";

export const useIsDarkTheme = () => {
    const isDark = useMediaQuery("(prefers-color-scheme: dark)");

    return isDark;
};

export const useIsMobile = () => {
    return useMediaQuery("(max-width: 768px)");
};
