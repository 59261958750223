import { createContext, useState } from "react";

export const IDContext = createContext();

const AppIdProvider = ({ children }) => {
  const [id, setId] = useState(null);

  return (
    <IDContext.Provider value={{ id, setId }}>{children}</IDContext.Provider>
  );
};

export default AppIdProvider;
