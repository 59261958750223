import {create} from 'zustand';

export const useUserStore = create((set) => ({
    user: null,
    settings: null,
    companies: [],
    setUser: (user) => set({user}),
    setSettings: (settings) => set({settings}),
    setCompanies: (companies) => set({companies}),
    clear: () => set({user: null, settings: null, companies: []}),
}))
