import { ConfigProvider, App, theme } from "antd";
import { ProProvider, enUSIntl } from "@ant-design/pro-components";
import roRo from "antd/es/locale/ro_RO";

import { useIsDarkTheme } from "@/utils/media-query";

const { darkAlgorithm } = theme;

const AntdStyleProvider = (props) => {
  const { token } = theme.useToken();

  return (
    <div
      {...props}
      style={{ backgroundColor: token.colorBgContainer }}
      className="min-h-screen"
    />
  );
};

const AntdConfigProvider = (props) => {
  const { children } = props;

  const isDarkTheme = useIsDarkTheme();

  return (
    <ConfigProvider
      theme={{
        algorithm: isDarkTheme ? darkAlgorithm : undefined,
        token: {
          motion: true,
          borderRadius: 4,
          fontFamily: "Inter",
        },
      }}
      locale={roRo}
    >
      <ProProvider.Provider
        value={{
          intl: enUSIntl,
        }}
      >
        <AntdStyleProvider>
          <App>{children}</App>
        </AntdStyleProvider>
      </ProProvider.Provider>
    </ConfigProvider>
  );
};

export default AntdConfigProvider;
