import { useState, useEffect } from "react";
import { useAuth, hasAuthParams } from "react-oidc-context";

const KeycloakAutoSignIn = (props) => {
  const { children } = props;

  const auth = useAuth();
  const [hasTriedSignin, setHasTriedSignin] = useState(false);

  useEffect(() => {
    if (auth.isAuthenticated) return;
    if (hasTriedSignin) return;
    if (auth.activeNavigator) return;
    if (auth.isLoading) return;
    if (hasAuthParams()) return;

    auth.signinRedirect();
    setHasTriedSignin(true);
  }, [auth, hasTriedSignin]);

  if (auth.isLoading) return null;

  return children;
};

export default KeycloakAutoSignIn;
