import {User} from "oidc-client-ts";

// WARNING: Use useAuth from react-oidc-context inside of components, this is supposed to get the user imperatively
export const getKeycloakUser = () => {
    const oidcStorage = localStorage.getItem(
        `oidc.user:${import.meta.env.VITE_KEYCLOAK_AUTHORITY}:${
            import.meta.env.VITE_KEYCLOAK_CLIENT_ID
        }`
    );
    if (!oidcStorage) return null;
    return User.fromStorageString(oidcStorage);
};
