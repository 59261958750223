import React, { useState } from "react";

import queryClient from "@/services/react-query";

const ReactQueryDevtoolsProduction = React.lazy(() =>
  import("@tanstack/react-query-devtools/build/modern/production.js").then(
    (d) => ({
      default: d.ReactQueryDevtools,
    })
  )
);

const LOCAL_STORAGE_KEY = "react-query-devtools";
const ReactQueryDevtools = () => {
  const localStorageIsOpen = JSON.parse(
    localStorage.getItem(LOCAL_STORAGE_KEY) ?? "false"
  );
  const [isOpen, setIsOpen] = useState(localStorageIsOpen);

  React.useEffect(() => {
    if (import.meta.env.PROD) return;

    window.toggleReactQueryDevtools = () => {
      setIsOpen((isOpen) => {
        localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(!isOpen));

        return !isOpen;
      });
    };
    return () => delete window.toggleReactQueryDevtools;
  }, []);

  if (import.meta.env.PROD) return null;

  return isOpen ? (
    <React.Suspense fallback={null}>
      <ReactQueryDevtoolsProduction client={queryClient} />
    </React.Suspense>
  ) : null;
};

export default ReactQueryDevtools;
