import {Button, Result} from "antd";
import {Link} from "@/services/router/index.js";

const NotFound = () => {
  return <Result
    status="404"
    title="404"
    subTitle="Ne pare rau dar aceasta pagina nu exista."
    extra={<Link to="/"><Button type="primary">Inapoi la prima pagina</Button></Link>}
  />;
};

export default NotFound;
