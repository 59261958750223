import {Outlet, useRouteError} from "react-router-dom";
import {Result} from "antd";
import * as Sentry from "@sentry/react";
import {useAuth} from "react-oidc-context";
import {useUserStore} from "@/stores/user.js";
import {useEffect} from "react";
import LoadingPage from "@/components/common/LoadingPage/index.jsx";

export const Loader = async () => {
    // console.log('App Loader')
    return null;
}

export const Catch = () => {
    const error = useRouteError();

    if (import.meta.env.DEV) console.error(error);
    if (import.meta.env.PROD) Sentry.captureMessage(error.toString(), error);

    return (
        <Result
            title="Se pare ca avem o eroare"
            status="500"
            subTitle={error.toString()}
        />
    );
};

const RootLayout = () => {
    const auth = useAuth();
    const setUser = useUserStore((state) => state.setUser);
    const user = useUserStore((state) => state.user);
    useEffect(() => {
        if (auth.user && auth.user.profile) {
            setUser(auth.user.profile);
        } else {
            setUser(null);
        }
    }, [auth.user, setUser]);


    if (user === null) {
        return <LoadingPage/>;
    }

    return <Outlet/>;
};

export default RootLayout;
