import * as Sentry from "@sentry/react";
import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import relativeTime from "dayjs/plugin/relativeTime";
import timezone from "dayjs/plugin/timezone";
import React from "react";
import ReactDOM from "react-dom/client";
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";
import { OpenAPI } from "@bapp/api-client";

import { getKeycloakUser } from "@/utils/auth";
import ReactQueryProvider from "@/components/providers/ReactQueryProvider";
import KeycloakProvider from "@/components/providers/KeycloakProvider";
import KeycloakAutoSignIn from "@/components/common/KeycloakAutoSignIn";
import AntdConfigProvider from "@/components/providers/AntdConfigProvider";
import AppIdProvider from "./components/providers/AppIdProvider";
import ReactQueryDevtools from "@/components/common/ReactQueryDevtools";
import { HelmetProvider } from "react-helmet-async";
import { Routes } from "@generouted/react-router/lazy";

import "@/index.css";
import "antd/dist/reset.css";
import "/node_modules/react-grid-layout/css/styles.css";
import "/node_modules/react-resizable/css/styles.css";

if (import.meta.env.PROD) {
  Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    // Performance Monitoring
    tracesSampleRate: 0.5, //  Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.

    integrations: [
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect: React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
    ],
  });
}

// Extend dayjs with plugins
dayjs.extend(localizedFormat);
dayjs.extend(relativeTime);
dayjs.extend(timezone);
dayjs.tz.setDefault("Europe/Bucharest");

// BAPP API setup
const getAccessToken = async () => {
  const user = getKeycloakUser();

  if (!user) return "";

  if (user.expired) return "";

  return user.access_token;
};

OpenAPI.TOKEN = getAccessToken;
if (!import.meta.env.PROD) {
  OpenAPI.BASE = import.meta.env.VITE_API_URL;
}

ReactDOM.createRoot(document.getElementById("bapp-root")).render(
  <KeycloakProvider>
    <KeycloakAutoSignIn>
      <ReactQueryProvider>
        <AntdConfigProvider>
          <HelmetProvider>
            <AppIdProvider>
              <Routes />
            </AppIdProvider>
          </HelmetProvider>
          <ReactQueryDevtools />
        </AntdConfigProvider>
      </ReactQueryProvider>
    </KeycloakAutoSignIn>
  </KeycloakProvider>
);
