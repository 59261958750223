import { WebStorageStateStore } from "oidc-client-ts";
import { AuthProvider } from "react-oidc-context";

const getRedirectUri = () => {
  const currentUrl = new URL(location.href);

  // Add redirect_to param, read handleSignIn comments below
  currentUrl.searchParams.set("redirect_to", encodeURIComponent(location.href));

  return currentUrl.toString();
};

const KEYCLOAK_OIDC_CONFIG = {
  authority: import.meta.env.VITE_KEYCLOAK_AUTHORITY,
  client_id: import.meta.env.VITE_KEYCLOAK_CLIENT_ID,
  redirect_uri: getRedirectUri(),
  automaticSilentRenew: true,

  userStore: new WebStorageStateStore({
    store: window.localStorage,
  }),

  onSigninCallback: () => {
    const url = new URL(location.href);
    const redirectTo = url.searchParams.get("redirect_to");

    if (!redirectTo) return;

    // Redirect to redirect_to(which is the initial URL user came from) to remove auth params
    const redirectUrl = decodeURIComponent(redirectTo);
    location.replace(redirectUrl);
  },
};

const KeycloakProvider = (props) => {
  return <AuthProvider {...props} {...KEYCLOAK_OIDC_CONFIG} />;
};

export default KeycloakProvider;
