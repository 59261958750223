import { Flex, Spin } from "antd";
import { useTranslation } from "react-i18next";

const LoadingPage = () => {
  const { t } = useTranslation();

  return (
    <Flex
      className="min-h-screen"
      justify="center"
      align="center"
      gap="small"
      vertical
    >
      <Spin size="large" />

      <span>{t("general.loading")}...</span>
    </Flex>
  );
};

export default LoadingPage;
